import React, { useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../../components/Layout'

import Spacing from '../../components/partials/Spacing'
import SmallSpacing from '../../components/partials/SmallSpacing'
import HeaderSEO from '../../components/partials/HeaderSEO'
import Logos from '../../components/partials/Logos'
import { StaticImage } from 'gatsby-plugin-image'

import administration from '../../resources/images/aiven_page/administration.svg'
import data_discovery from '../../resources/images/aiven_page/data_discovery.svg'
import data_processing from '../../resources/images/aiven_page/data_processing.svg'
import monitoring from '../../resources/images/aiven_page/monitoring.svg'
import security from '../../resources/images/aiven_page/security.svg'

import kafka from '../../resources/images/aiven_page/kafka.svg'
import kafka_connect from '../../resources/images/aiven_page/kafka_connect.svg'
import mirror_maker from '../../resources/images/aiven_page/mirror_maker.svg'
import schema_registry from '../../resources/images/aiven_page/schema_registry.svg'
import rest_proxy from '../../resources/images/aiven_page/rest_proxy.svg'

import aws from '../../resources/images/aiven_page/aws.svg'
import azure from '../../resources/images/aiven_page/azure.svg'
import google_cloud_platform_logo from '../../resources/images/aiven_page/google_cloud_platform_logo.svg'
import digital_ocean from '../../resources/images/aiven_page/digital_ocean.svg'
import upcloud_logo_horizontal from '../../resources/images/aiven_page/upcloud_logo_horizontal.svg'

import aiven_logo from '../../resources/images/aiven_page/aiven_logo.svg'
import lenses_logo from '../../resources/images/aiven_page/lenses_logo.svg'

import { initializeVideos } from '../../helpers/initializeVideo'

const Aiven = () => {
  const data = useStaticQuery(graphql`
    query AivenSEO {
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "CLOUD_Aiven" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  useEffect(() => {
    try {
      initializeVideos()
    } catch (err) {
      console.log(err)
    }
  }, [])

  return (
    <Layout newsletter={false}>
      <HeaderSEO pageSEO={pageSEO} />
      <section className="overflow-hidden container-1 mx-auto">
        <div className="d-flex flex-wrap hero-margin align-items-center">
          <div className="col-lg-5 col-xl-5 col-md-8 col-12 mobile-padding-left">
            <p className="h4-like mb-1">AIVEN + LENSES FOR KAFKA</p>
            <h1>Secure Managed Kafka with DataOps</h1>
            <div className="pr-5">
              <h2 className="hero-description-dark pt-4 roboto">
                Power up your Kafka with a full-spectrum streaming data platform
                complete with a user-friendly data experience and scalable data
                governance features.
              </h2>
            </div>
            <div className="my-3">
              <div className="roll-button mobile-margin-bottom-half">
                <a
                  href="/downloads/lenses-enterprise/?s=aiven"
                  className="bg-light-blue text-white cursor-pointer"
                >
                  <span data-hover="Get Lenses for my Aiven">
                    Get Lenses for my Aiven
                  </span>
                </a>
              </div>
              <div className="d-flex flex-wrap">
                <div className="roll-button mt-2 mobile-margin-reset">
                  <a
                    className=" bg-red text-white"
                    href="/contact-us/?s=aiven-discount"
                  >
                    <span data-hover="&nbsp; Get Aiven with Lenses &nbsp;">
                      &nbsp; Get Aiven with Lenses &nbsp;
                    </span>
                  </a>
                </div>
                <div className="primary-text f-14 aiven-discount">
                  <b> &rarr; claim $350 free credits</b>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-xl-6 offset-xl-1 pt-0 col-md-8 col-12 px-0 mobile-margin-top tablet-margin-top">
            <StaticImage
              className="img-fluid w-100"
              src="../../resources/images/aiven_page/kafka_hero.svg"
              placeholder="tracedSVG"
              alt="Aiven hero"
            />
          </div>
        </div>
      </section>

      <Spacing />
      <Logos width={75} />
      <Spacing />
      <div className="container-1">
        <div className="d-flex flex-wrap">
          <div className="col-12 col-sm-10">
            <h2 className="w-40 mobile-w-100 mobile-margin-top">
              Why choose Kafka & DataOps as-a-service?
            </h2>

            <p>
              Apache Kafka is the foundation of much modern data infrastructure.
              But the time taken to self-manage and maintain it snowballs as you
              scale - creating an endless list of tickets, feature requests and
              distractions from developing your core apps.
            </p>
            <p>
              Aiven is a fully managed streaming platform for Kafka, deployable
              in the cloud of your choice. Lenses provides DataOps for you to
              monitor, explore, govern and build data and flows in your managed
              Kafka.
            </p>

            <p>
              <b>
                Aiven with Lenses helps you avoid wrong-turns with your data
                projects, offering the most direct route from zero to business
                impact with Apache Kafka.
              </b>
            </p>
          </div>
        </div>
      </div>

      {/* <!-- Testimonial --> */}
      <Spacing />
      <div className="container-1 mobile-margin-top">
        <p className="h4-like text-center mb-3">Benefits</p>

        <section>
          <div className="container-1">
            <div className="flex-wrap d-flex align-items-center">
              <div className="col-md-6">
                <h2 className="">Managed Kafka by Aiven</h2>
                <div className="pr-3">
                  <ul className="red-checked-list mb-2 pl-0">
                    <li>
                      <span>
                        Deploy Kafka in your choice of 5 clouds and 85+ regions
                      </span>
                    </li>
                    <li>
                      <span>
                        Integrate with your infra workflow, from metrics to logs
                        and authentication
                      </span>
                    </li>
                    <li>
                      <span>
                        Enjoy 99% uptime - goodbye outages & panicked weekend
                        calls
                      </span>
                    </li>
                    <li>
                      <span>
                        Managed schema registry and Kafka Connect - all your
                        Kafka components in one place
                      </span>
                    </li>
                    <li>
                      <span>Lower your Kafka TCO</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="offset-md-1"></div>
              <div className="col-md-5 mobile-margin-top-half">
                <div className="pt-3">
                  <StaticImage
                    className="img-fluid mx-auto d-block"
                    src="../../resources/images/aiven_page/cloud.png"
                    placeholder="tracedSVG"
                    alt="Managed Kafka by Aiven"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <Spacing />

        <section>
          <div className="container-1 mobile-margin-top">
            <div className="flex-wrap d-flex align-items-center">
              <div className="col-md-5 ">
                <div>
                  <StaticImage
                    className="img-fluid mx-auto d-block"
                    src="../../resources/images/aiven_page/lenses.png"
                    placeholder="tracedSVG"
                    alt="DataOps by lenses.io"
                  />
                </div>
              </div>
              <div className="offset-md-1"></div>
              <div className="col-md-6 mobile-margin-top-half ">
                <h2>DataOps by Lenses.io</h2>
                <div className="pr-3 ">
                  <ul className="red-checked-list mb-2 pl-0">
                    <li>
                      <span>
                        100% Kafka self-service for everyone without needing
                        Kafka skills
                      </span>
                    </li>
                    <li>
                      <span>
                        Instant time-to-insight of your streaming data
                      </span>
                    </li>
                    <li>
                      <span>No need for DIY management tooling</span>
                    </li>
                    <li>
                      <span>
                        Build stream processing applications without code
                      </span>
                    </li>
                    <li>
                      <span>
                        Apply agnostic governance and compliance controls
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Spacing />

      <section className="bg-light-blue  home-banner-height mobile-margin-top">
        <div className="container-1 h-100 d-flex align-self-center">
          <div className="d-flex flex-wrap align-items-center">
            <div className="offset-sm-1 offset-0"></div>
            <div className="col-12 col-sm-4 ">
              <h2 className="text-white pb-0">Win $350 free Kafka credit</h2>

              <p className="text-white justify-self-center align-self-center">
                Start your Aiven & Lenses trial today to receive months worth of
                free fully managed Kafka.
              </p>
              <div className="d-flex flex-wrap w-100">
                <img
                  className="pl-0 col-sm-4 col-lg-3 col-md-4 col-2 mt-2"
                  src={lenses_logo}
                  alt="lenses"
                />
                <img
                  className="pl-0 col-sm-3 col-lg-2 col-md-3 col-2 mt-2"
                  src={aiven_logo}
                  alt="Aiven"
                />
              </div>
            </div>
            <div className="offset-sm-3 offset-3"></div>
            <div className="col-sm-3 col-9">
              <div className="roll-button">
                <a
                  className="px-5  bg-white button-mobile-font-size downloadbutton"
                  href="/contact-us/?s=aiven-discount"
                >
                  <span data-hover="Start now">Start now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="footer-color">
        <Spacing />
        <div className="container-1 mobile-padding-top">
          <h2 className="text-center ">Our users say…</h2>
          <div className="d-flex mt-4 mb-2 flex-wrap align-items-center justify-content-center">
            <div className="col-6 col-sm-7  testimonial-open-source py-3 ">
              <StaticImage
                className="testimonial-open-source-company-image"
                src="../../resources/images/homepage/testimonials/marineTraffic.png"
                placeholder="tracedSVG"
                alt="Marine Traffic Testimonial"
              />
              <p className="testimonial-text">
                “Lenses.io & Aiven help us harness all Kafka’s powerful
                complexity and make it more available & actionable for us.”{' '}
              </p>
              <a className="primary-text " href="/customers/marine-traffic/">
                Read case study <i className="red-pointer-right"></i>
              </a>
              <StaticImage
                className="arrow-right-image"
                src="../../resources/images/aiven_page/triangle.svg"
                placeholder="tracedSVG"
                alt="triangle"
              />
            </div>

            <div className="offset-2 offset-sm-1 col-3 flex-column flex-sm-row align-items-center d-flex mobile-padding-reset">
              <StaticImage
                imgClassName="quote-image"
                className="rounded-1 w-75"
                src="../../resources/images/aiven_page/christos_marine.jpg"
                placeholder="tracedSVG"
                alt="Christos"
              />
              <div className="ml-3 d-flex w-100 flex-column mobile-margin-reset">
                <div>
                  <p className="light-blue fw-400 mb-0"> Christos</p>
                </div>
                <div>Back-end Team Leader</div>
              </div>
            </div>
          </div>
          <Spacing />
          <div className="d-flex flex-wrap ">
            <div className="col-lg text-center">
              <p className="h2-like pb-0 mb-1 cera-pro"> 95% less time</p>
              <p className="cera-pro">in delivering flows</p>
            </div>
            <div className="col-lg text-center">
              <p className="h2-like pb-0 mb-1 cera-pro">10+%</p>
              <p className="cera-pro">in developer productivity</p>
            </div>
            <div className="col-lg text-center mobile-padding-bottom">
              <p className="h2-like pb-0 mb-1 cera-pro">Higher focus</p>
              <p className="cera-pro">on strategic commitments</p>
            </div>
          </div>
        </div>

        <SmallSpacing />
      </section>

      <Spacing />
      <div className="container-1 mobile-margin-top">
        <p className="h4-like text-center mb-3">Features</p>
        <div className="d-flex flex-wrap">
          <div className="mx-auto col-sm-6 col-12">
            <h2 className="text-center">
              Components of an Apache Kafka data platform
            </h2>
            <p className="text-center">
              Aiven and Lenses.io offer a complete solution for Managed Apache
              Kafka and a toolkit and integrations for DataOps, including the
              following features:
            </p>
          </div>
        </div>

        <div className="d-flex flex-wrap mobile-margin-top">
          <div className="col-12">
            <p className="h4-like mb-3">Lenses</p>
          </div>
          <div className="col-12 lenses-border-aiven mb-3">
            <div className="d-flex flex-wrap justify-content-between">
              <div className="col-lg col-sm-4 col-12 my-2">
                <div className="d-flex flex-wrap flex-sm-row flex-column align-items-center primary-text px-1">
                  <img
                    className="mr-2"
                    src={administration}
                    alt="Administration"
                  />
                  <div className="text-uppercase f-14 fw-500 cera-pro">
                    Self-service <br /> Admin
                  </div>
                  <p className="mt-2 f-13 mb-0">
                    Visibility and alerting on app and flow health.
                  </p>
                </div>
              </div>
              <div className="col-lg col-sm-4 col-12 my-2">
                <div className="d-flex flex-wrap flex-sm-row flex-column align-items-center primary-text px-1">
                  <img
                    className="mr-2"
                    src={data_discovery}
                    alt="Data Discovery"
                  />
                  <div className="text-uppercase f-14 fw-500 cera-pro">
                    Data <br /> Discovery
                  </div>
                  <p className="mt-2 f-13 mb-0">
                    Search, debug and investigate data in Kafka.
                  </p>
                </div>
              </div>
              <div className="col-lg col-sm-4 col-12 my-2">
                <div className="d-flex flex-wrap flex-sm-row flex-column align-items-center primary-text px-1">
                  <img
                    className="mr-2"
                    src={data_processing}
                    alt="Data Processing"
                  />
                  <div className="text-uppercase f-14 fw-500 cera-pro">
                    Data <br /> Processing
                  </div>
                  <p className="mt-2 f-13 mb-0">
                    Accelerate deployment across your data fabric.
                  </p>
                </div>
              </div>
              <div className="col-lg col-sm-4 col-12 my-2">
                <div className="d-flex flex-wrap flex-sm-row flex-column align-items-center primary-text px-1">
                  <img
                    className="mr-2"
                    src={monitoring}
                    alt="Monitoring and Observability"
                  />
                  <div className="text-uppercase f-14 fw-500 cera-pro">
                    Monitoring & <br /> Observability
                  </div>
                  <p className="mt-2 f-13 mb-0">
                    Insights into your Kafka and data performance.
                  </p>
                </div>
              </div>
              <div className="col-lg col-sm-4 col-12 my-2">
                <div className="d-flex flex-wrap flex-sm-row flex-column align-items-center primary-text px-1">
                  <img
                    className="mr-2"
                    src={security}
                    alt="Security and governance"
                  />
                  <div className="text-uppercase f-14 fw-500 cera-pro">
                    Security & <br /> governance
                  </div>
                  <p className="mt-2 f-13 mb-0">
                    Enterprise Kafka security, data policies & role-based
                    access.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <p className="h4-like mb-3 mt-4 aiven-gray-line">Aiven</p>
          </div>
          <div className="col-12 aiven-border-aiven mb-3 mobile-padding-reset">
            <div className="d-flex flex-wrap justify-content-between">
              <div className="col-lg col-sm-4 col-12 my-2">
                <div className="d-flex flex-wrap flex-row align-items-center aiven-color justify-content-center">
                  <img
                    className="mr-2"
                    src={kafka_connect}
                    alt="Kafka Connect"
                  />
                  <div className="text-uppercase f-14 fw-500 cera-pro ">
                    Kafka <br /> Connect
                  </div>
                </div>
              </div>
              <div className="col-lg col-sm-4 col-12 my-2">
                <div className="d-flex flex-wrap flex-row align-items-center aiven-color justify-content-center">
                  <img className="mr-2" src={mirror_maker} alt="Mirror Maker" />
                  <div className="text-uppercase f-14 fw-500 cera-pro">
                    Mirror <br /> Maker
                  </div>
                </div>
              </div>
              <div className="col-lg col-sm-4 col-12 my-2">
                <div className="d-flex flex-wrap flex-row align-items-center aiven-color justify-content-center">
                  <img className="mr-2" src={kafka} alt="Kafka" />
                  <div className="text-uppercase f-14 fw-500 cera-pro">
                    kafka
                  </div>
                </div>
              </div>
              <div className="col-lg col-sm-4 col-12 my-2">
                <div className="d-flex flex-wrap flex-row align-items-center aiven-color justify-content-center">
                  <img
                    className="mr-2"
                    src={schema_registry}
                    alt="Schema Registry"
                  />
                  <div className="text-uppercase f-14 fw-500 cera-pro">
                    Schema <br /> Registry
                  </div>
                </div>
              </div>
              <div className="col-lg col-sm-4 col-12 my-2">
                <div className="d-flex flex-wrap flex-row align-items-center aiven-color justify-content-center">
                  <img className="mr-2" src={rest_proxy} alt="Rest Proxy" />
                  <div className="text-uppercase f-14 fw-500 cera-pro">
                    Rest <br /> Proxy
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <p className="h4-like cloud-gray-line mb-3 mt-4">Cloud</p>
          </div>
          <div className="col-12 cloud-border-aiven mobile-padding-reset">
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <div className="col-md col-sm-4 col-12 my-2 xs-text-center">
                <img
                  className="mobile-margin-top-half"
                  src={aws}
                  alt="Amazon Web Services"
                />
              </div>
              <div className="col-md col-sm-4 col-12 my-2 xs-text-center">
                <img
                  className="mobile-margin-top-half"
                  src={google_cloud_platform_logo}
                  alt="Google Cloud Platform"
                />
              </div>
              <div className="col-md col-sm-4 col-12 my-2 xs-text-center">
                <img
                  className="mobile-margin-top-half"
                  src={azure}
                  alt="Azure"
                />
              </div>
              <div className="col-md col-sm-4 col-12 my-2 xs-text-center">
                <img
                  className="mobile-margin-top-half"
                  src={digital_ocean}
                  alt="Digital Ocean"
                />
              </div>
              <div className="col-md col-sm-4 col-12 my-2 xs-text-center">
                <img
                  className="mobile-margin-top-half mobile-margin-bottom-half"
                  src={upcloud_logo_horizontal}
                  alt="UpCloud"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Spacing />
        <section>
          <div className="container-1">
            <div className="d-flex flex-wrap">
              <div className="col-md-5 col-10 pb-0 mobile-padding-left tablet-padding-left">
                <p className="mb-1 mt-5 f-16 h4-like pb-0">How to</p>
                <h2>Try out Aiven with</h2>
              </div>
              <div className="col-md-1 col-12"></div>
              <div className="col-md-6 col-12">
                <div className="cloud-aws-msk-video">
                  <iframe
                    src=""
                    data-src="//player.vimeo.com/video/443057175"
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%',
                    }}
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </section> */}

      <Spacing />

      <section>
        <div className="container-1">
          <div className="d-flex flex-wrap">
            <div className="col">
              <p className="mb-1 mt-5 f-16 h4-like pb-0">Featured</p>
              <h2>Managed Kafka resources</h2>
            </div>
          </div>
          <div className="d-flex flex-wrap">
            <div className="col-md col-sm-6 col-12 mt-1">
              <div className="home-three-items-box border-top-0 rounded-1">
                <StaticImage
                  className="w-100"
                  src="../../resources/images/aiven_page/marine_traffic.png"
                  placeholder="tracedSVG"
                  alt="Marine Traffic"
                />
                <div className="py-4 px-3">
                  <p className="h4-like mb-1">Case Study</p>
                  <div>
                    <p className="cera-pro d-inline">
                      How MarineTraffic maps shipping fleet data with{' '}
                    </p>
                    <h3 className="f-19 d-inline">Apache Kafka</h3>
                  </div>
                  <a
                    className="primary-text home-link-box"
                    href="/customers/marine-traffic/"
                  >
                    Read the full story <i className="red-pointer-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md col-sm-6 col-12 mt-1">
              <div className="home-three-items-box border-top-0 rounded-1">
                <StaticImage
                  className="w-100"
                  src="../../resources/images/aiven_page/aiven_blog.jpg"
                  placeholder="tracedSVG"
                  alt="Aiven"
                />
                <div className="py-4 px-3">
                  <p className="h4-like mb-1">Blog</p>
                  <div>
                    <p className="cera-pro d-inline">
                      The top requirements for building performant real-time
                      applications
                    </p>
                  </div>
                  <a
                    className="primary-text home-link-box"
                    href="/blog/2020/10/the-importance-of-load-testing-kafka/"
                  >
                    Read blog <i className="red-pointer-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md col-sm-6 col-12 mt-1">
              <div className="home-three-items-box border-top-0 rounded-1">
                <StaticImage
                  className="w-100"
                  src="../../resources/images/aiven_page/webinar.jpg"
                  placeholder="tracedSVG"
                  alt="Webinar"
                />
                <div className="py-4 px-3">
                  <p className="h4-like mb-1">Webinar</p>
                  <p className="cera-pro">
                    Apache Kafka Office Hours: Load testing your Apache Kafka
                    cluster
                  </p>
                  <a
                    className="primary-text home-link-box"
                    href="/videos/apache-kafka-office-hours-aiven-load-testing/"
                  >
                    Watch video <i className="red-pointer-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Spacing />
      <section className="px-5 mobile-padding-reset mobile-margin-top">
        <div className="container-1 bg-dark p-5 rounded-1 mobile-round-0 ">
          <div className="text-center pb-2">
            <p className="cera-pro text-white paragraph-title f-24 fw-400">
              Want to try managed Kafka with DataOps?
            </p>
          </div>
          <div className="d-flex flex-wrap justify-content-center align-items-center">
            <div className="roll-button mobile-margin-bottom-half mr-2 mt-1">
              <a
                href="/downloads/lenses-enterprise/?s=aiven"
                className="bg-light-blue text-white cursor-pointer"
              >
                <span data-hover="Get Lenses for my Aiven">
                  Get Lenses for my Aiven
                </span>
              </a>
            </div>

            <div className="roll-button mobile-margin-reset mt-1">
              <a
                className=" bg-red text-white"
                href="/contact-us/?s=aiven-discount"
              >
                <span data-hover="&nbsp; Get Aiven with Lenses &nbsp;">
                  &nbsp; Get Aiven with Lenses &nbsp;
                </span>
              </a>
            </div>
          </div>
          <div className="d-flex flex-wrap">
            <div className="col-12 offset-1 offset-sm-3 offset-md-6 col-sm-12 col-md-6">
              <div className="primary-text f-14 ">
                <b>claim $350 free credits</b>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Spacing />
    </Layout>
  )
}

export default Aiven
